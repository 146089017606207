<template>
  <tr :class="`${scrollClass} ${customClass} `">
    <td
      v-if="isChildRow || isCheckboxEnabled"
      :class="
        `${getActiveClass}  ${customClass}  ${isFixed ? 'sticky left-0 z-5 ' : ''} ${
          row?.isActive ? 'border-t border-solid border-blue-400 active-td' : 'border-t inactive-td'
        }`.replace('bg-gray-50', !isChildRow ? 'bg-white' : 'bg-gray-50')
      "
      class="w-full p-4 items-end"
    >
      <span v-if="isChildRow" class="cursor-pointer" @click="$emit('expandRow', row)">
        <SvgSprite
          :class="`${!row.isActive ? 'fill-gray-500 -rotate-90' : 'fill-blue-500'}`"
          :symbol="`${!row.isActive ? 'arrow-down-new' : 'arrow-down-new'}`"
          class="w-3 h-3 ml-1"
        />
      </span>

      <ElementsInputsTheCheckbox
        v-else-if="isCheckboxEnabled"
        :id="`checkbox_${row?.id?.title}`"
        :disabled="handleFloatedNumberString(row?.total_available_qty?.title) === 0"
        :is-checked="row?.isChecked?.title"
        :is-checked-all="isCheckedAll"
        :selected-ids="selectedIds"
        :value="row.id?.title"
        @handle-check-box="handleCheckBox"
      />
      <span v-else></span>
    </td>
    <template v-for="col in columns" :key="col.key">
      <td
        v-if="!col?.shouldHide"
        :class="
          `${col.key} ${getNormalizeValue(col.className)} ${customClass} ${
            col.key === 'source' && isFixed
              ? `sticky left-0 z-5  ${
                  parentColumns.filter((col) => col.key === 'type' && !col.shouldHide)?.length ? 'left-28' : 'left-14'
                } `
              : ''
          }  ${row?.isActive ? 'border-t border-solid border-blue-400 active-td' : 'inactive-td'}`.replace(
            '',
            row?.isActive ? 'bg-sky-50' : ''
          )
        "
        class="border-t px-4 py-2"
        @mouseleave="() => isExpandableCol(col.key) && isFixed && handleMouseOver(false)"
        @mouseover="() => isExpandableCol(col.key) && isFixed && handleMouseOver(true)"
      >
        <TheHtmlString
          v-if="col?.formatValue || row[col.key]?.formatValue"
          v-tooltip.right="
            row[col.key]?.tooltip
              ? {
                  content: row[col.key]?.tooltipTitle,
                  html: true
                }
              : {}
          "
          :class="`${row[col.key]?.handleClick ? 'cursor-pointer text-blue-600' : 'text-gray-700'} ${
            !!col?.isResizable ? 'w-54  ' : ''
          }${col?.hasLowerCase ? ' lowercase' : ''}`"
          :html-tag="`span`"
          :html-text="
            row[col.key]?.tooltip && !!col?.isResizable
              ? row[col.key]?.tooltipTitle
              : col.formatValue
              ? `${col.formatValue(row[col.key]?.title, row)}`
              : `${row[col.key]?.formatValue(row[col.key]?.title, row)}`
          "
          class="text-sm 2xl:text-base"
          @click.prevent="() => (row[col.key]?.handleClick ? row[col.key]?.handleClick(row) : undefined)"
        />

        <template v-else-if="col?.component">
          <Component
            :is="col.component.name"
            v-tooltip.right="
              row[col.key]?.tooltip
                ? {
                    content: row[col.key]?.tooltipTitle,
                    html: true
                  }
                : {}
            "
            :class="`${getNormalizeValue(col?.component?.iconClass)}${getNormalizeValue(
              col?.component?.isModel ? ' cursor-pointer' : ''
            )}`"
            :symbol="(col?.component?.icon ? col?.component?.icon : row?.asset_icon?.title) || 'no-use'"
            :to="col.component?.toLink"
            v-bind="{ ...col.component.props, row: row, error: row?.errors }"
            @handle-event="col?.component?.handleEvent"
            >{{ row[col.key].tooltipTitle }}
          </Component>
        </template>
        <ClearLink
          v-else-if="col?.toURL || row[col.key]?.toURL"
          v-tooltip.right="
            row[col.key]?.tooltip
              ? {
                  content: row[col.key]?.tooltipTitle,
                  html: true
                }
              : {}
          "
          :element-id="col.key"
          :target="row[col.key]?.target || '_self'"
          :to-link="row[col.key]?.toURL || col?.toURL"
          class="text-blue-600 max-w-xs inline-block text-sm 2xl:text-base"
        >
          {{ row[col.key]?.tooltip && !!col?.isResizable ? row[col.key]?.tooltipTitle : row[col.key]?.title }}
        </ClearLink>
        <TheHtmlString
          v-else
          v-tooltip.right="
            row[col.key]?.tooltip
              ? {
                  content: row[col.key]?.tooltipTitle,
                  html: true
                }
              : {}
          "
          :html-class="`${col?.isRightAligned ? 'text-right' : ''}${col?.hasLowerCase ? ' lowercase' : ''}`"
          :html-tag="`div`"
          :html-text="
            row[col.key]?.title === 'NaN'
              ? `0`
              : `${row[col.key]?.tooltip && !!col?.isResizable ? row[col.key]?.tooltipTitle : row[col.key]?.title}`
          "
          class="text-gray-700 text-sm 2xl:text-base"
        />
        <div v-if="isLastColumn && col.key === 'source'" class="asset-source">&nbsp;</div>
      </td>
    </template>
    <template v-if="extraTdWithHiddenCols > 0">
      <td
        v-for="th in extraTdWithHiddenCols"
        :key="th"
        :class="`${row?.isActive || isChildRow ? 'bg-gray-50' : ''} ${
          row?.isActive ? 'border-solid border-blue-400 active-td' : 'inactive-td'
        } ${customClass}`"
        class="border-t p-4 parent"
      >
        <span>&nbsp;</span>
      </td>
    </template>
    <td
      v-if="isLastColumn"
      :class="
        `last-column ${customClass} ${
          (isChildRow || row?.type?.title === 'B') && row?.isActive
            ? 'border-t border-solid border-blue-400 active-td'
            : 'border-t inactive-td'
        }  ${isFixed ? 'sticky right-0 z-5' : ''}  `.replace(
          ' ',
          row?.isActive || (!isChildRow && props.row?.type?.title === 'P')
            ? `${scrollClass}--fill-color `
            : ` ${row?.isActive && isChildRow ? ' active-child active-td' : `${scrollClass}--no-fill inactive-td`}`
        )
      "
    >
      <template v-if="nuxtApp?.$ability?.can('update', 'Basket')">
        <ClearLink
          v-if="
            isShowColumnFilter &&
            !hasChildren &&
            !isChildRow &&
            (row?.asset_source?.title !== 'Transferred' || row?.is_transferred?.title !== 'Transferred')
          "
          :element-id="`column-filter`"
          :to-link="`/assets/update-basket-${row?.id?.title}`"
        >
          <SvgSprite class="w-4 h-4 m-auto fill-gray-500" symbol="new--plus" />
        </ClearLink>
      </template>
      <span v-else> <SvgSprite class="w-4 h-4 m-auto fill-gray-500 invisible" symbol="new--plus" /></span>
      <div class="active-row">&nbsp;</div>
    </td>
  </tr>
</template>
<script setup>
import { SvgSprite } from 'vue-svg-sprite';
import { computed, ref } from 'vue';
import { getNormalizeValue, handleFloatedNumberString } from '@/utils/config';
import { isExpandableCol } from '~/utils/commonFunction';
import { useNuxtApp } from '#imports';

const nuxtApp = ref(null);
if (process.client) {
  nuxtApp.value = useNuxtApp();
}
const props = defineProps({
  columns: {
    type: Array,
    required: true
  },
  parentColumns: {
    type: Array,
    default: () => []
  },
  extraTds: {
    type: Number,
    default: 0
  },
  row: {
    type: Object,
    required: true
  },
  isAllCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isCheckboxEnabled: {
    type: Boolean,
    default: false
  },
  isShowColumnFilter: {
    type: Boolean,
    default: false
  },
  isAssetFilter: {
    type: Boolean,
    default: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  isCheckedAll: {
    type: Boolean,
    default: false
  },
  hasChildren: {
    type: Boolean,
    default: false
  },
  isChildRow: {
    type: Boolean,
    default: false
  },
  customClass: {
    type: String,
    default: ''
  },
  isFixed: {
    type: Boolean,
    default: false
  },
  isLastRow: {
    type: Boolean,
    default: false
  },
  isLastColumn: {
    type: Boolean,
    default: false
  },
  scrollClass: {
    type: String,
    default: ''
  }
});

const getActiveClass = computed(() => {
  let normalClass = getNormalizeValue(props.customClass + (props.row?.isActive ? ' active-parent ' : ''));
  if (props.row?.errors) {
    normalClass += ' error';
  }
  if (props.isChildRow || props.row?.type?.title === 'B') {
    normalClass = normalClass.replace('bg-white', '') + ' bg-white';
  }
  if (props.row?.isActive) {
    normalClass = normalClass.replace('bg-white', '') + ' bg-sky-50';
  }
  return normalClass;
});
const selectedIds = ref([]);
const isMouseOver = ref(false);
const extraTdWithHiddenCols = computed(() => props.extraTds);
const emit = defineEmits(['handleCheckBox', 'update:modelValue', 'update:isCheckedAll', 'expandRow']);

const handleCheckBox = (e) => {
  emit('handleCheckBox', e, props.row);
};
const handleMouseOver = (status) => {
  isMouseOver.value = status;
};
</script>
